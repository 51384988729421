import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue3-apexcharts";
import { loadFonts } from "./plugins/webfontloader";
import { Amplify } from "aws-amplify";
loadFonts();
// document.addEventListener("contextmenu", (e) => e.preventDefault());

// document.addEventListener("keydown", (e) => {
//   if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I") || (e.ctrlKey && e.shiftKey && e.key === "J") || (e.ctrlKey && e.key === "U")) {
//     e.preventDefault();
//   }
// });

Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === "dev.commdealdata.com" 
        ? "us-east-1_Uzbc6S9Sn"
        : window.location.hostname === "test.commdealdata.com" || window.location.hostname == "localhost" || window.location.hostname === "192.168.0.253"
        ? "us-east-1_OwaiYs3Lb"
        : window.location.hostname === "uat.commdealdata.com"
        ? "us-east-1_rS56nrtPi"
        : "us-east-1_Gkh7ez0Uq",
    region: "us-east-1",
    userPoolWebClientId:
      window.location.hostname === "dev.commdealdata.com" 
        ? "5qoco7d6duhurgpk4lu52b7912"
        : window.location.hostname === "test.commdealdata.com" || window.location.hostname == "localhost" || window.location.hostname === "192.168.0.253"
        ? "6jebv5bhirm09tmss2akenut49"
        : window.location.hostname === "uat.commdealdata.com"
        ? "2l0urgg0l5o01ik630ik24k0bc"
        : "3quqdu6aafq2ufqdf0uv10a9kn",
  },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      window.location.hostname === "dev.commdealdata.com"
        ? "https://jgpsb67xf5axlkk2ex5zkrmkfa.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "test.commdealdata.com"  || window.location.hostname == "localhost" || window.location.hostname === "192.168.0.253"
        ? "https://mlki5rcaqnd5nabs3r3cguktjy.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://5hzvh6fbmjg2jaw5oxnkrefl7q.appsync-api.us-east-1.amazonaws.com/graphql"
        : "https://2qp6zni7izce5h72mmlemyvjvm.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "DEALHOUR",
        endpoint: "https://d1f7r2w2n1.execute-api.us-east-1.amazonaws.com/uat/",
      },
    ],
  },
});

createApp(App).use(router).use(store).use(vuetify).use(VueApexCharts).mount("#app");
